import { css } from '@emotion/react';
import CloseIcon from '@material-ui/icons/Close';
import React, { VFC, ComponentProps } from 'react';
import RabbitPlease from '@/images/modal/modalRabbitPlease.png';
import { BaseModal } from '@/libs/components/common';
import { color } from '@/styles/theme';

type CustomProps = {
  modalClose: () => void;
};

type Props = ComponentProps<typeof BaseModal> & CustomProps;

export const AlertModal: VFC<Props> = ({ modalClose, children, ...attr }) => {
  return (
    <BaseModal {...attr}>
      <header css={header}>
        <button css={closeButton} onClick={modalClose}>
          <CloseIcon />
        </button>
      </header>
      <div css={modalContainer}>
        <div css={thumbnail}>
          <img src={RabbitPlease} alt="" css={thumbnailIcon} />
        </div>
        {children}
      </div>
    </BaseModal>
  );
};

const modalContainer = css`
  position: relative;
  z-index: 1;
  padding: 16px;
`;

const header = css`
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  top: 8px;
  width: min(100%, 480px);
`;

const thumbnail = css`
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -184px;
  z-index: -1;
`;

const thumbnailIcon = css`
  height: auto;
  width: 143px;
`;

const closeButton = css`
  position: fixed;
  top: 0;
  right: 0;

  svg {
    color: ${color.gray[0]};
    height: 40px;
    width: 40px;
  }
`;
