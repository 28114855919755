import { css } from '@emotion/react';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { SelectChipItem } from '@/libs/components/common';
import { HookFormProps } from '@/types';

type Options = {
  options: Array<{
    label: string;
    value: string;
  }>;
};

type Props<T> = HookFormProps<T> & Options;

export const SelectChips = <T extends FieldValues>({
  options,
  ...attr
}: Props<T>) => {
  return (
    <div css={wrapper}>
      {options.map(({ label, value }) => (
        <SelectChipItem<T> key={value} value={value} label={label} {...attr} />
      ))}
    </div>
  );
};

const wrapper = css`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;
