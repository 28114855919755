import { fetchQuery, GraphQLTaggedNode } from 'react-relay';
import {
  CacheConfig,
  FetchQueryFetchPolicy,
  OperationType,
} from 'relay-runtime';
import { initEnvironment } from '..';
import { QueryPayload } from 'react-relay-network-modern';

type relayData = null | [[string, QueryPayload]];

export const fetchQueryInSSR = async <T extends OperationType>(
  query: GraphQLTaggedNode,
  variables: T['variables'] = {},
  cacheConfig?: {
    networkCacheConfig?: CacheConfig | null;
    fetchPolicy?: FetchQueryFetchPolicy | null;
  } | null
): Promise<relayData> => {
  const { environment, relaySSR }: ReturnType<typeof initEnvironment> =
    initEnvironment();

  await fetchQuery<T>(environment, query, variables, cacheConfig).toPromise();
  const [cacheData] = await relaySSR.getCache();
  const [cacheKey, payload] = cacheData;
  return !cacheData || !('json' in payload) ? null : [[cacheKey, payload.json]];
};
