import { css } from '@emotion/react';
import React, { VFC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const Container: VFC<Props> = ({ children }) => {
  return <div css={container}>{children}</div>;
};

const container = css`
  padding: 0 16px;
`;
