import React, { ComponentProps } from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { BaseCheckbox } from './BaseCheckbox';
import { HookFormProps } from '@/types';

type Props<T> = HookFormProps<T> &
  Omit<ComponentProps<typeof BaseCheckbox>, 'active'>;

export const Checkbox = <T extends FieldValues>(props: Props<T>) => {
  const { name, rules, ...attr } = props;
  const { register } = useFormContext();
  const checked = !!useWatch({ name });
  return <BaseCheckbox {...register(name, rules)} {...attr} active={checked} />;
};
