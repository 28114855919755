import {
  VFC,
  ReactNode,
  createContext,
  useContext,
  useReducer,
  Reducer,
  useCallback,
} from 'react';

type State = boolean;
type Action = 'open' | 'close' | 'toggle';

const reducer: Reducer<State, Action> = (state, action): State => {
  switch (action) {
    case 'open':
      return true;
    case 'close':
      return false;
    case 'toggle':
      return !state;
    default:
      return state;
  }
};

const noOp = () => {
  // no-op
};

const Context = createContext<ReturnType<typeof useMethods>>({
  isOpen: false,
  setOpen: noOp,
  setClose: noOp,
  toggle: noOp,
});

const useMethods = () => {
  const [isOpen, dispatch] = useReducer(reducer, false);
  const setOpen = useCallback(() => dispatch('open'), []);
  const setClose = useCallback(() => dispatch('close'), []);
  const toggle = useCallback(() => dispatch('toggle'), []);

  return { isOpen, setOpen, setClose, toggle };
};

type Props = {
  children: ReactNode;
};

export const ModalOpenProvider: VFC<Props> = ({ children }) => {
  return <Context.Provider value={useMethods()}>{children}</Context.Provider>;
};

export const useModalOpenContext = () => useContext(Context);
