import { css } from '@emotion/react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { forwardRef, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { color, fontSize } from '@/styles/theme';

type CustomProps = {
  label: string;
  active: boolean;
};

type Props = JSX.IntrinsicElements['input'] & CustomProps;

export const BaseCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ label, active, ...attr }, ref) => {
    const id = useRef(uuid());
    return (
      <div css={checkWrap}>
        <CheckBoxIcon css={checkButton(active)} />
        <input
          css={baseStyle}
          type="checkbox"
          id={id.current}
          {...attr}
          ref={ref}
        />
        <label css={checkLabel} htmlFor={id.current}>
          {label}
        </label>
      </div>
    );
  }
);

const checkWrap = css`
  align-items: center;
  display: flex;
  position: relative;
`;

const checkButton = (active: boolean | undefined) => css`
  color: ${active ? color.blue[60] : color.gray[40]};
  padding: 4px;

  && {
    height: 40px;
    width: 40px;
  }
`;

const baseStyle = css`
  cursor: pointer;
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 40px;
`;

const checkLabel = css`
  font-size: ${fontSize.body[2]}px;
`;
