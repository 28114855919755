import { Suspense, VFC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RelayProvider } from '@/libs/components/RelayProvider';
import { Routes } from '@/libs/components/Routes';
import { ScrollToTop } from '@/libs/components/ScrollToTop';
import { TagManagerSet } from '@/libs/components/TagManagerSet';
import { AuthUserProvider } from '@/libs/components/auth';
import { LoadingBubble } from '@/libs/components/common';

export const App: VFC = () => {
  return (
    <Suspense fallback={<LoadingBubble />}>
      <BrowserRouter>
        <ScrollToTop />
        <AuthUserProvider>
          <TagManagerSet />
          <RelayProvider>
            <Routes />
          </RelayProvider>
        </AuthUserProvider>
      </BrowserRouter>
    </Suspense>
  );
};
