import { useEffect, useMemo, VFC } from 'react';
import TagManager from 'react-gtm-module';
import { useAuthUserContext } from './auth/AuthUserProvider';

export const TagManagerSet: VFC = () => {
  const user = useAuthUserContext();
  const tagManagerArgs = useMemo(
    () => ({
      gtmId: 'GTM-5CG66KT',
      dataLayer: {
        user_id: user.uid,
      },
    }),
    [user]
  );

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, [tagManagerArgs]);

  return null;
};
