import { useCallback } from 'react';
import { applogging } from '@/libs/appLogging';

export const useAuthEmailSender = () => {
  const send = useCallback(
    async (email: string, mode: string): Promise<void> => {
      const res = await fetch(
        `${process.env.NX_SAMURAI_NEXT_URL}/moving/api/send-auth-email`,
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            email,
            mode,
            domain: 'mitsumori.a-tm.co.jp',
          }),
        }
      );
      if (!res.ok) {
        const response = await res.json();
        // バックエンド側とエラーコードが異なるため、ステータスコードで変換する
        // FIXME: バックエンド側で変換して返してもらう or そもそもサーバサイドで認証処理する等を検討する
        applogging({
          logLabel: 'ERROR',
          message: JSON.stringify(response),
          module: 'Signup/MailAddressForReSend:sendAuthEmail',
        });
        switch (response.code) {
          case '400':
            throw new Error('auth/too-many-requests');
          default:
            throw new Error('auth/unknown');
        }
      }
    },
    []
  );
  return { send };
};
