import firebase from 'firebase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userDB } from '@/firebase/db';
import { applogging } from '@/libs/appLogging';

export const useVerifyProC = (user: firebase.User | null) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      userDB
        .mainQuery(user.uid)
        .get()
        .then((doc) => {
          // ステータスが登録済だがforHasuraのデータがないときは進化ユーザー
          if (!doc.exists) {
            applogging({
              logLabel: 'INFO',
              message: '進化ユーザー：/change-serviceへリダイレクトしました',
              module: 'useVerifyProC',
              user: user,
            });
            navigate('/change-service');
          }
        })
        .catch((e) => {
          applogging({
            logLabel: 'ERROR',
            message: e.message,
            module: 'useVerifyProC',
            user: user,
          });
        });
    }
  }, [user, navigate]);
};
