import { css } from '@emotion/react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { forwardRef } from 'react';
import { color, fontSize } from '@/styles/theme';

type CustomProps = {
  isCorrect: boolean;
};

type Props = JSX.IntrinsicElements['select'] & CustomProps;

export const BaseSelect = forwardRef<HTMLSelectElement, Props>(
  ({ isCorrect, ...attr }, ref) => {
    return (
      <div css={selectWrap}>
        <select css={[baseStyle, optionStyle(isCorrect)]} ref={ref} {...attr} />
        <KeyboardArrowDownIcon />
      </div>
    );
  }
);

const selectWrap = css`
  position: relative;
  margin-top: 4px;

  svg {
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const baseStyle = css`
  background-color: ${color.gray[0]};
  border-radius: 8px;
  display: flex;
  font-size: ${fontSize.body[1]}px;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
`;

const optionStyle = (isCorrect: boolean) => css`
  border: 1px solid ${isCorrect ? color.blue[60] : color.divider.onSurface};
`;
