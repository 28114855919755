import { Dayjs } from 'dayjs';
import { dayjs } from './localizedDayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const parseTime = (textTime: string): Dayjs => {
  return dayjs(textTime, 'HH:mm:ss');
};

export const timeToHourAndMin = (textTime: string): string => {
  const parsedTime = dayjs(textTime, 'HH:mm');

  const template =
    dayjs().format('DD') === parsedTime.format('DD') ? 'HH:mm' : 'kk:mm';

  return parseTime(textTime).format(template);
};
