import dayjs, { ConfigType } from 'dayjs';
import 'dayjs/locale/ja';

const days = {
  sunday: {
    num: 0,
  },
  saturday: {
    num: 6,
  },
} as const;

type Day = keyof typeof days;

const getDayNum = (day: Day) => {
  return days[day].num;
};

export const isDayOfWeek = (date: ConfigType, day: Day) => {
  return dayjs(date).day() === getDayNum(day);
};
