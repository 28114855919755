import { css } from '@emotion/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { color, elevation } from '@/styles/theme';

type Props = {
  link: string;
};

export const Header: FC<Props> = ({ link }) => {
  return (
    <header css={header}>
      <Link to={link} css={navigationItem}>
        <ArrowBackIosIcon />
        戻る
      </Link>
    </header>
  );
};

const header = css`
  background-color: ${color.gray[0]};
  box-shadow: ${elevation[16]};
  color: ${color.text.mediumEmphasis};
  display: flex;
  padding: 16px;
`;

const navigationItem = css`
  display: flex;
`;
