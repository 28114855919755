import { lazy } from 'react';
import { useRoutes, RouteObject } from 'react-router-dom';
import { AccessControl } from './AccessControl';

const EstimateSelect = lazy(() => import('@/components/EstimateSelect'));
const IntroductionSummary = lazy(
  () => import('@/components/IntroductionSummary')
);
const Login = lazy(() => import('@/components/Login'));
const ChangeService = lazy(() => import('@/components/ChangeService'));
const ChoiceService = lazy(() => import('@/components/ChoiceService'));
const PasswordReset = lazy(() => import('@/components/PasswordResset'));
const SentPasswordMail = lazy(() => import('@/components/SentPasswordMail'));
const Map = lazy(() => import('@/components/Map'));
const Schedule = lazy(() => import('@/components/Schedule'));
const Signup = lazy(() => import('@/components/Signup'));
const MailAddressReSend = lazy(() => import('@/components/MailAddressResend'));
const SentConfirmationMail = lazy(
  () => import('@/components/SentConfirmationMail')
);
const Chat = lazy(() => import('@/components/Chat'));
const ChatTutorial = lazy(() => import('@/components/ChatTutorial'));
const CompanyDetail = lazy(() => import('@/components/CompanyDetail'));
const CompanyEstimates = lazy(() => import('@/components/CompanyEstimates'));
const Confirm = lazy(() => import('@/components/Confirm'));
const Faq = lazy(() => import('@/components/Faq'));
const FromAddress = lazy(() => import('@/components/FromAddress'));
const Furniture = lazy(() => import('@/components/Furniture'));
const InputConfirm = lazy(() => import('@/components/InputConfirm'));
const Step3ClearMap = lazy(() => import('@/components/Step3ClearMap'));
const MyPage = lazy(() => import('@/components/MyPage'));
const MyPageTutorial = lazy(() => import('@/components/MyPageTutorial'));
const PersonalInfo = lazy(() => import('@/components/PersonalInfo'));
const VerifyEmailSuccess = lazy(
  () => import('@/components/VerifyEmailSuccess')
);
const ToAddress = lazy(() => import('@/components/ToAddress'));
const AllComplete = lazy(() => import('@/components/AllComplete'));
const Introduction = lazy(() => import('@/components/Introduction'));
const Landing = lazy(() => import('@/components/Landing'));
const NotFound = lazy(() => import('@/components/NotFound'));
const SessionError = lazy(() => import('@/components/SessionError'));
const Sorry = lazy(() => import('@/components/Sorry'));
const Terms = lazy(() => import('@/components/Terms'));
const TooManyRequests = lazy(() => import('@/components/TooManyRequests'));
const UnexpectedError = lazy(() => import('@/components/UnexpectedError'));

export const Routes = () => {
  return useRoutes(routeConfig);
};

const routeConfig: RouteObject[] = [
  {
    path: '/all-complete',
    element: (
      <AccessControl component={<AllComplete />} accessOnly="verified" />
    ),
  },
  {
    path: '/chat/:chatroomId',
    element: <AccessControl component={<Chat />} accessOnly="verified" />,
  },
  {
    path: '/company-estimates',
    element: (
      <AccessControl component={<CompanyEstimates />} accessOnly="verified" />
    ),
  },
  {
    path: '/input-confirm',
    element: (
      <AccessControl component={<InputConfirm />} accessOnly="verified" />
    ),
  },
  {
    path: '/estimate-select',
    element: (
      <AccessControl component={<EstimateSelect />} accessOnly="verified" />
    ),
  },
  {
    path: '/my-page',
    element: <AccessControl component={<MyPage />} accessOnly="verified" />,
  },
  {
    path: '/my-page-tutorial',
    element: (
      <AccessControl component={<MyPageTutorial />} accessOnly="verified" />
    ),
  },
  {
    path: '/personal-info',
    element: (
      <AccessControl component={<PersonalInfo />} accessOnly="verified" />
    ),
  },
  {
    path: '/company-detail',
    element: (
      <AccessControl component={<CompanyDetail />} accessOnly="verified" />
    ),
  },
  {
    path: '/confirm',
    element: <AccessControl component={<Confirm />} accessOnly="verified" />,
  },
  {
    path: '/faq',
    element: <AccessControl component={<Faq />} accessOnly="verified" />,
  },
  {
    path: '/verify-email-success',
    element: (
      <AccessControl component={<VerifyEmailSuccess />} accessOnly="verified" />
    ),
  },
  {
    path: '/chat-tutorial',
    element: (
      <AccessControl component={<ChatTutorial />} accessOnly="verified" />
    ),
  },
  {
    path: '/from-address',
    element: (
      <AccessControl component={<FromAddress />} accessOnly="anonymous" />
    ),
  },
  {
    path: '/furniture',
    element: <AccessControl component={<Furniture />} accessOnly="anonymous" />,
  },
  {
    path: '/login',
    element: <AccessControl component={<Login />} accessOnly="anonymous" />,
  },
  {
    path: '/schedule',
    element: <AccessControl component={<Schedule />} accessOnly="anonymous" />,
  },
  {
    path: '/signup',
    element: <AccessControl component={<Signup />} accessOnly="anonymous" />,
  },
  {
    path: '/to-address',
    element: <AccessControl component={<ToAddress />} accessOnly="anonymous" />,
  },
  {
    path: '/password-reset',
    element: (
      <AccessControl component={<PasswordReset />} accessOnly="anonymous" />
    ),
  },
  {
    path: '/sent-confirmation-mail',
    element: (
      <AccessControl
        component={<SentConfirmationMail />}
        accessOnly="notVerified"
      />
    ),
  },
  {
    path: '/mail-address-resend',
    element: (
      <AccessControl
        component={<MailAddressReSend />}
        accessOnly="notVerified"
      />
    ),
  },
  {
    path: '/sent-password-mail',
    element: (
      <AccessControl
        component={<SentPasswordMail />}
        accessOnly="notVerified"
      />
    ),
  },
  { path: '/', element: <Landing /> },
  { path: '/too-many-requests', element: <TooManyRequests /> },
  { path: '/change-service', element: <ChangeService /> },
  { path: '/choice-service', element: <ChoiceService /> },
  { path: '/introduction', element: <Introduction /> },
  { path: '/introduction-summary', element: <IntroductionSummary /> },
  { path: '/map', element: <Map /> },
  { path: '/session-error', element: <SessionError /> },
  { path: '/sorry', element: <Sorry /> },
  { path: '/terms', element: <Terms /> },
  { path: '/unexpected-error', element: <UnexpectedError /> },
  { path: '/step3-clear-map', element: <Step3ClearMap /> },
  // [TODO]これだけだとステータスコードが200のままなので要調査
  { path: '*', element: <NotFound /> },
];
