import { css } from '@emotion/react';
import React, { forwardRef, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { color, fontSize } from '@/styles/theme';

type CustomProps = {
  label: string;
};

type Props = JSX.IntrinsicElements['input'] & CustomProps;

export const BaseSelectChipItem = forwardRef<HTMLInputElement, Props>(
  ({ label, ...attr }, ref) => {
    const id = useRef(uuid());
    return (
      <>
        <input
          id={id.current}
          type="radio"
          css={chipButton}
          ref={ref}
          {...attr}
        />
        <label css={chipLabel} htmlFor={id.current}>
          {label}
        </label>
      </>
    );
  }
);

const chipButton = css`
  display: none;

  &:checked + label {
    background: ${color.blue[20]};
    border: 1px solid ${color.blue[60]};
    color: ${color.blue[80]};
  }
`;

const chipLabel = css`
  display: block;
  border: 1px solid ${color.divider.onSurface};
  border-radius: 22px;
  box-sizing: border-box;
  font-size: ${fontSize.body[1]}px;
  padding: 8px 16px;
`;
