import { css } from '@emotion/react';
import { formatDate } from '@samurai/shared/utils/date';
import React, { VFC, ReactNode } from 'react';
import { color, fontSize } from '@/styles/theme';

type Props = {
  children: ReactNode;
};

export const Layout: VFC<Props> = ({ children }) => {
  const date = new Date();
  const currentYear = formatDate(date, 'YYYY');

  return (
    <div css={content}>
      <div css={layout}>{children}</div>
      <footer css={footer}>
        <span role="contentinfo" css={copyright}>
          <a
            href="https://life-design.a-tm.co.jp/corporate/"
            target="_blank"
            rel="noreferrer"
            css={link}
          >
            &copy; {currentYear} Ateam LifeDesign Inc.
          </a>
        </span>
      </footer>
    </div>
  );
};

const content = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 480px;
  min-height: 100vh;
`;

const layout = css`
  flex-grow: 1;
  padding-bottom: 24px;
  width: 100%;
`;

const footer = css`
  background: ${color.gray[90]};
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;

const copyright = css`
  color: ${color.gray[0]};
  font-size: ${fontSize.body[3]}px;
`;

const link = css`
  text-decoration: none;
`;
