import { css } from '@emotion/react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import character from '@/images/character/character.svg';
import samuraiLogo from '@/images/login/samurai.png';
import mitsumoruLogo from '@/images/logo/logo.svg';
import { PrimaryButton } from '@/libs/components/common';
import { fontSize, color } from '@/styles/theme';

type Props = {
  service: string;
  choice?: boolean;
};

export const ServiceGuide: FC<Props> = ({ service, choice = false }) => {
  const navigate = useNavigate();

  return (
    <>
      <div css={container}>
        <h2 css={heading}>登録済みサービスのご案内</h2>
        <div css={block}>
          <p>
            {`お客様は株式会社エイチーム引越し侍が運営する${service}に登録済みです。`}
            <br />
            こちらよりログインしてください。
          </p>
          <div css={block}>
            <img
              css={logoImage}
              src={samuraiLogo}
              alt="ネット見積もりサービス"
            />
            <PrimaryButton
              css={button}
              onClick={() =>
                window.open(
                  'https://hikkoshizamurai.jp/moving/auth/signin',
                  '_blank'
                )
              }
            >
              ネット見積もりサービスへ
            </PrimaryButton>
          </div>
          {choice && (
            <div css={block}>
              <div css={logoBlock}>
                <img css={logoImageIcon} src={character} alt="" />
                <img css={logoImageItem} src={mitsumoruLogo} alt="mitsumoru" />
              </div>
              <PrimaryButton css={button} onClick={() => navigate('/login')}>
                mitsumoruへ
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const container = css`
  margin-top: 24px;
  padding: 0 16px;
`;

const heading = css`
  font-size: ${fontSize.headline[2]}px;
  font-weight: bold;
`;

const block = css`
  margin-top: 24px;
  &:nth-of-type(2) {
    border-top: 1px solid ${color.divider.onSurface};
    padding-top: 24px;
  }
`;

const logoBlock = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const logoImage = css`
  width: 100%;
`;

const logoImageIcon = css`
  height: auto;
  margin-right: 16px;
  width: 60px;
`;

const logoImageItem = css`
  height: 35px;
  width: 230px;
`;

const button = css`
  margin-top: 16px;
  width: 100%;
`;
