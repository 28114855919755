import React, { ComponentProps } from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { BaseTextarea } from './BaseTextarea';
import { HookFormProps } from '@/types';

type Props<T> = Omit<ComponentProps<typeof BaseTextarea>, 'isCorrect'> &
  HookFormProps<T>;

export const Textarea = <T extends FieldValues>(props: Props<T>) => {
  const { name, rules, ...attr } = props;
  const { register } = useFormContext();
  const isFilled = !!useWatch({ name });
  return (
    <BaseTextarea {...register(name, rules)} {...attr} isCorrect={isFilled} />
  );
};
