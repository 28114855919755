import React from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { BaseSelect } from './BaseSelect';
import { HookFormProps } from '@/types';

type Props<T> = React.ComponentPropsWithoutRef<'select'> & HookFormProps<T>;

export const Select = <T extends FieldValues>(props: Props<T>) => {
  const { name, rules, ...attr } = props;
  const { control, register } = useFormContext<T>();
  const isFilled = !!useWatch({ control, name: name });
  return (
    <BaseSelect {...register(name, rules)} {...attr} isCorrect={isFilled} />
  );
};
