import { css } from '@emotion/react';
import React, { forwardRef } from 'react';
import { color, fontSize } from '@/styles/theme';

type CustomProps = {
  isCorrect: boolean;
};

type Props = JSX.IntrinsicElements['input'] & CustomProps;

export const BaseInput = forwardRef<HTMLInputElement, Props>(
  ({ isCorrect, ...attr }, ref) => {
    return <input css={baseStyle(isCorrect)} ref={ref} {...attr} />;
  }
);

const baseStyle = (isCorrect: boolean) => css`
  background-color: ${color.gray[0]};
  border: 1px solid ${isCorrect ? color.blue[60] : color.divider.onSurface};
  border-radius: 8px;
  font-size: ${fontSize.body[1]}px;
  margin-top: 8px;
  padding: 8px;
  width: 100%;
`;
