import firebase from 'firebase';
import { createContext, ReactNode, useContext, VFC } from 'react';
import { LoadingBubble } from '../common';
import { useAuthUserGenerator, useVerifyProC } from '@/libs/hooks/auth';

const Context = createContext({} as firebase.User);

type Props = {
  children: ReactNode;
};

export const AuthUserProvider: VFC<Props> = ({ children }) => {
  const { user } = useAuthUserGenerator();
  useVerifyProC(user);

  if (!user) return <LoadingBubble />;
  return <Context.Provider value={user}>{children}</Context.Provider>;
};

export const useAuthUserContext = () => useContext(Context);
