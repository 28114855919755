export const color = {
  gray: {
    0: '#fff',
    10: '#f9f9f9',
    20: '#f2f2f2',
    30: '#dcdcdc',
    40: '#c2c2c3',
    50: '#a6a7a7',
    60: '#8a8b8b',
    70: '#6e6f70',
    80: '#545656',
    90: '#3d4040',
  },
  blue: {
    20: '#e4f3fb',
    40: '#b8def5',
    60: '#1d77c9',
    70: '#1b5ca7',
    80: '#14417b',
  },
  yellow: {
    20: '#faf2d6',
    40: '#f4df9a',
    60: '#f1a032',
    70: '#b67a0c',
    80: '#7e5701',
  },
  red: {
    20: '#f9e6e7',
    40: '#eeb4bc',
    60: '#d13354',
    70: '#a8153a',
    80: '#7e1035',
  },
  divider: {
    onBackground: 'rgba(255 255 255 / 0.2)',
    onSurface: 'rgba(0 0 0 / 0.12)',
  },
  text: {
    disabled: 'rgba(0 0 0 / 0.38)',
    mediumEmphasis: 'rgba(0 0 0 / 0.6)',
    highEmphasis: 'rgba(0 0 0 / 0.87)',
  },
  overlay: 'rgba(0 0 0 / 0.7)',
};

export const fontSize = {
  headline: {
    1: 32,
    2: 24,
  },
  subhead: {
    1: 20,
    2: 18,
  },
  body: {
    1: 16,
    2: 14,
    3: 12,
  },
};

export const lineHeight = {
  s: 1.4,
  m: 1.6,
  l: 1.8,
};

export const elevation = {
  8: '0px 2px 8px rgba(0, 0, 0, 0.12)',
  16: '0px 4px 16px rgba(0, 0, 0, 0.08)',
};
