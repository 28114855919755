import { FieldValues } from 'react-hook-form';
import { Input } from '@/libs/components/common';
import { HookFormProps } from '@/types';

type Props<T> = Pick<HookFormProps<T>, 'name'>;

export const MailAddressForm = <T extends FieldValues>({ name }: Props<T>) => {
  return (
    <Input
      name={name}
      placeholder="hikkoshi-madoguchi@example.com"
      rules={{
        required: {
          value: true,
          message: 'メールアドレスを入力して下さい',
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'メールアドレスの形式で入力して下さい',
        },
      }}
    />
  );
};
