import firebase from './config';

class Auth {
  private _auth: firebase.auth.Auth;

  constructor() {
    this._auth = firebase.auth();
  }

  public onAuthStateChanged(callback: (user: firebase.User | null) => void) {
    return this._auth.onAuthStateChanged(callback);
  }

  public async signInAnonymously() {
    return await this._auth.signInAnonymously();
  }

  public async signUp(email: string, password: string) {
    const cred = firebase.auth.EmailAuthProvider.credential(email, password);
    return await this._auth.currentUser?.linkWithCredential(cred);
  }

  public async signIn(email: string, password: string) {
    return await this._auth.signInWithEmailAndPassword(email, password);
  }

  public async signout() {
    return await this._auth.signOut();
  }
}

export const auth = new Auth();
