import { css } from '@emotion/react';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { SelectImageChipItem } from '@/libs/components/common';
import { HookFormProps } from '@/types';

type Options = {
  options: Array<{
    label: string;
    value: string;
    imagePath: string;
  }>;
};

type Props<T> = HookFormProps<T> & Options;

export const SelectImageChips = <T extends FieldValues>({
  options,
  ...attr
}: Props<T>) => {
  return (
    <div css={wrapper}>
      {options.map((option) => (
        <SelectImageChipItem<T> key={option.value} {...option} {...attr} />
      ))}
    </div>
  );
};

const wrapper = css`
  display: flex;
  gap: 4px;
  margin-top: 8px;
  padding-bottom: 16px;
  overflow-x: scroll;

  @media screen and (max-width: 480px) {
    padding-bottom: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
