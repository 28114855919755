import React, { ReactNode, VFC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthUserContext } from './auth';

type Props = {
  component: ReactNode;
  accessOnly: 'verified' | 'notVerified' | 'anonymous';
};

export const AccessControl: VFC<Props> = ({
  component: Component,
  accessOnly,
}) => {
  const user = useAuthUserContext();
  // メール認証まで終わっている
  if (accessOnly === 'verified') {
    if (user.isAnonymous || !user.emailVerified) {
      return <Navigate to="/login" />;
    }
  }
  // メール認証が終わっていない
  if (accessOnly === 'notVerified') {
    if (user.emailVerified) {
      return <Navigate to="/my-page" />;
    } else if (user.isAnonymous) {
      return <Navigate to="/login" />;
    }
  }
  // 匿名状態
  if (accessOnly === 'anonymous') {
    if (user.emailVerified) {
      return <Navigate to="/my-page" />;
    } else if (!user.isAnonymous) {
      return <Navigate to="/mail-address-resend" />;
    }
  }
  return <>{Component}</>;
};
