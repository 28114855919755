import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.NX_FIREBASE_API_KEY,
  authDomain: `${process.env.NX_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.NX_FIREBASE_PROJECT_ID,
};
firebase.initializeApp(firebaseConfig);

export default firebase;
