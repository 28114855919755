import { css } from '@emotion/react';
import React, { VFC, ComponentProps } from 'react';
import { BaseButton } from '@/libs/components/common';
import { color } from '@/styles/theme';

type Props = ComponentProps<typeof BaseButton>;

export const SecondaryButton: VFC<Props> = (props) => {
  return <BaseButton css={secondaryStyle} {...props} />;
};

const secondaryStyle = css`
  background-color: ${color.gray[0]};
  border-color: ${color.blue[60]};
  color: ${color.blue[80]};

  &:disabled {
    border-color: ${color.gray[40]};
    color: ${color.gray[60]};
  }

  &:active:not(:disabled) {
    background-color: ${color.gray[20]};
    border-color: ${color.blue[80]};
  }
`;
