import firebase from '@/firebase/config';

type LogLabel = 'INFO' | 'DEBUG' | 'ERROR' | 'DEV';

export type AppLoggingData = {
  logLabel: LogLabel;
  message: string;
  module: string;
  user?: firebase.User | string;
  requestId?: number;
};

type LoggingData = {
  logLabel: LogLabel;
  userId: string;
  requestId: number;
  userType: string;
  module: string;
  message: string;
};

// 基本は受け取って渡すだけのmethod
export const applogging = (appLog: AppLoggingData): void => {
  const userId = appLog.user
    ? typeof appLog.user === 'string'
      ? appLog.user
      : appLog.user.uid
      ? appLog.user.uid
      : 'user.uid none'
    : 'user none';

  const userType = appLog.user
    ? typeof appLog.user === 'string'
      ? 'id only'
      : appLog.user.isAnonymous
      ? '匿名ユーザー'
      : appLog.user.emailVerified
      ? '本登録ユーザー'
      : '仮登録ユーザー'
    : 'user none';

  const data: LoggingData = {
    logLabel: appLog.logLabel,
    userId: userId,
    requestId: appLog.requestId || 0,
    userType: userType,
    module: appLog.module,
    message: appLog.message,
  };

  // 本番以外はconsoleにも出力
  window.location.hostname !== 'mitsumori.a-tm.co.jp' &&
    console.log(JSON.stringify(data));

  // DEV以外はCloudLoggingに書き込み（DEVはconsole.log出力のみ）
  if (appLog.logLabel !== 'DEV') {
    const functions = firebase
      .app()
      .functions()
      .httpsCallable('loggingAndSend');
    functions(data);
    //ここでのthen、catchは意味無し
  }
};
