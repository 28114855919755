import React, { VFC, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  children: ReactNode;
};

export const FormMethodProvider: VFC<Props> = ({ children }) => {
  const methods = useForm();
  return <FormProvider {...methods}>{children}</FormProvider>;
};
