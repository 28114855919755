import { css } from '@emotion/react';
import React, { VFC, ElementType } from 'react';
import { fontSize } from '@/styles/theme';

type Props = {
  tag?: ElementType;
  text: string;
};

export const HeadLabel: VFC<Props> = ({ tag: Tag = 'h2', text }) => {
  return <Tag css={subhead}>{text}</Tag>;
};

const subhead = css`
  font-size: ${fontSize.subhead[2]}px;
  font-weight: bold;
  margin-top: 24px;
`;
