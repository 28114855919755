import { css } from '@emotion/react';
import React, { VFC } from 'react';
import { color } from '@/styles/theme';

type Props = {
  recordList: {
    head: string;
    data: string;
  }[];
  thWidth?: number;
};

export const Table: VFC<Props> = ({ recordList, thWidth = 120 }) => {
  return (
    <table css={table(thWidth)}>
      <tbody>
        {recordList.map(({ head, data }, i) => (
          <tr key={i}>
            <th>{head}</th>
            <td dangerouslySetInnerHTML={{ __html: data }} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const table = (thWidth: number) => css`
  width: 100%;
  margin-top: 8px;

  th {
    background-color: ${color.gray[20]};
    border-color: ${color.divider.onSurface};
    border-style: solid;
    border-width: 1px 0;
    color: ${color.text.mediumEmphasis};
    font-weight: bold;
    width: ${thWidth}px;
    padding: 8px;
  }
  td {
    border-color: ${color.divider.onSurface};
    border-style: solid;
    border-width: 1px 0;
    min-width: 120px;
    padding: 8px;
  }

  .textLink{
    color: ${color.blue[60]};
    text-decoration: underline;
  },
`;
