import { css } from '@emotion/react';
import React, { VFC, ComponentProps } from 'react';
import { BaseButton } from '@/libs/components/common';
import { color } from '@/styles/theme';

type Props = ComponentProps<typeof BaseButton>;

export const PrimaryButton: VFC<Props> = (props) => {
  return <BaseButton css={primaryStyle} {...props} />;
};

const primaryStyle = css`
  background-color: ${color.blue[60]};
  color: ${color.gray[0]};

  &:disabled {
    background-color: ${color.gray[30]};
    color: ${color.gray[70]};
  }

  &:active:not(:disabled) {
    background-color: ${color.blue[70]};
  }
`;
