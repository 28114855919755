import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { auth } from '@/firebase/auth';
import { applogging } from '@/libs/appLogging';

export const useAuthUserGenerator = () => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        auth
          .signInAnonymously()
          .then((res) => {
            applogging({
              logLabel: 'INFO',
              message: '匿名ユーザーの作成に成功',
              module: 'useAuthUserGenerator',
              user: res.user || undefined,
            });
          })
          .catch((e) => {
            applogging({
              logLabel: 'ERROR',
              message: `匿名ユーザーの作成に失敗:${e.code}, ${e.message}`,
              module: 'useAuthUserGenerator',
            });
          });
      } else {
        setUser(user);
      }
    });
  }, []);

  return { user };
};
