import { css } from '@emotion/react';
import React, { FC, ReactNode } from 'react';
import characterImage from '@/images/character/character.svg';
import { color } from '@/styles/theme';

type Props = {
  children: ReactNode;
};

export const RabbitInformation: FC<Props> = ({ children, ...props }) => {
  return (
    <div css={information} {...props}>
      <img css={character} src={characterImage} alt="" />
      <p css={descriptionStyle}>{children}</p>
    </div>
  );
};

const information = css`
  align-items: flex-start;
  background: ${color.gray[20]};
  border-radius: 8px;
  display: flex;
  padding: 16px;
`;

const character = css`
  width: 44px;
`;

const descriptionStyle = css`
  margin-left: 12px;
`;
