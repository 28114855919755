// https://github.com/vercel/next.js/blob/canary/examples/with-react-relay-network-modern/lib/createEnvironment/client.js
import {
  RelayNetworkLayer,
  cacheMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern/node8';
import RelaySSR from 'react-relay-network-modern-ssr/node8/client';
import { Environment, RecordSource, Store } from 'relay-runtime';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { SSRCache } from 'react-relay-network-modern-ssr/lib/server';
import * as equal from 'fast-deep-equal';

// TODO: [HIE-1845] Mutation後限定でキャッシュ参照させずに再取得させる方法の検討
const queryCacheExpirationTime = 10 * 1000;

const source = new RecordSource();
const store = new Store(source, { queryCacheExpirationTime });

let storeEnvironment: RelayModernEnvironment | null = null;
let storeData: SSRCache | null | undefined = null;
let storeJwt: undefined | string = undefined;

export const createEnvironment = (
  url: string,
  relayData: SSRCache | null | undefined,
  jwt: string | undefined,
  role: 'company' | 'user'
) => {
  if (storeEnvironment && eqWithStoreData(relayData) && storeJwt === jwt)
    return storeEnvironment;
  storeData = relayData;
  storeJwt = jwt;

  storeEnvironment = new Environment({
    store,
    network: new RelayNetworkLayer([
      cacheMiddleware({
        size: 100,
        ttl: 60 * 1000,
      }),
      new RelaySSR(relayData ?? []).getMiddleware({
        lookup: false,
      }),
      urlMiddleware({
        url,
        headers: _setHeader(jwt, role),
      }),
    ]),
  });

  return storeEnvironment;
};

export const initEnvironment = () => {
  // no-op
};

export const _setHeader = (
  jwt: string | undefined,
  role: string
): Record<string, string> => {
  if (!jwt)
    return {
      'x-hasura-role': 'anonymous',
    };
  return {
    Authorization: `Bearer ${jwt}`,
    'x-hasura-role': role,
  };
};

const eqWithStoreData = (relayData: SSRCache | null | undefined) => {
  if (!storeData || !relayData) return false;
  return equal(
    storeData.map(([cacheKey]) => cacheKey).sort(),
    relayData.map(([cacheKey]) => cacheKey).sort()
  );
};
