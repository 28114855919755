import { css } from '@emotion/react';
import React, { forwardRef, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { color, fontSize, lineHeight } from '@/styles/theme';

type CustomProps = {
  label: string;
  imagePath: string;
};

type Props = JSX.IntrinsicElements['input'] & CustomProps;

export const BaseSelectImageChipItem = forwardRef<HTMLInputElement, Props>(
  ({ label, imagePath, ...attr }, ref) => {
    const id = useRef(uuid());
    return (
      <>
        <input
          id={id.current}
          type="radio"
          css={chipButton}
          ref={ref}
          {...attr}
        />
        <label css={chipCard} htmlFor={id.current}>
          <img css={chipImage} src={imagePath} alt="" />
          <p css={chipLabel}>{label}</p>
        </label>
      </>
    );
  }
);

const chipButton = css`
  display: none;

  &:checked + label {
    background: ${color.blue[20]};
    border: 1px solid ${color.blue[60]};
    color: ${color.blue[80]};
  }
`;

const chipCard = css`
  border: 1px solid ${color.divider.onSurface};
  border-radius: 8px;
  display: inline-block;
  padding: 16px;
  width: 152px;
`;

const chipLabel = css`
  font-size: ${fontSize.body[2]}px;
  line-height: ${lineHeight.s};
  margin-top: 16px;
  text-align: center;
`;

const chipImage = css`
  display: block;
  width: 120px;
`;
