import { css } from '@emotion/react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { color } from '@/styles/theme';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const RabbitComment: FC<Props> = ({ children, onClick }) => {
  return (
    <div css={comment}>
      <p className="click_button_serif-skip">{children}</p>
      <Link to="#" css={commentIcon} onClick={onClick}>
        <ArrowRightIcon />
      </Link>
    </div>
  );
};

const comment = css`
  background: ${color.gray[0]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  min-height: 140px;
  padding: 16px 16px 8px;
`;

const commentIcon = css`
  align-self: flex-end;
  color: ${color.blue[60]};
  margin-top: 4px;

  svg {
    display: block;
    height: 36px;
    width: 36px;
  }
`;
