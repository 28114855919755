import { css } from '@emotion/react';
import React from 'react';
import { useFormContext, get, FieldPath, FieldValues } from 'react-hook-form';
import { color, fontSize } from '@/styles/theme';

type Props<T> = {
  name: FieldPath<T>;
};

export const ErrorMessage = <T extends FieldValues>({ name }: Props<T>) => {
  const {
    formState: { errors },
  } = useFormContext<T>();
  const error = get(errors, name);

  if (!error) return null;
  return <span css={errorMessage}>{error.message}</span>;
};

const errorMessage = css`
  color: ${color.red[60]};
  font-size: ${fontSize.body[3]}px;
  display: block;
`;
