import { css } from '@emotion/react';
import React, { FC, ReactNode } from 'react';
import { fontSize, color } from '@/styles/theme';

type Props = {
  children: ReactNode;
  required?: boolean;
  marginTop?: number;
};

export const FormLabel: FC<Props> = ({
  children,
  required,
  marginTop = 24,
}) => {
  return (
    <label css={label(marginTop)}>
      <span css={[base, option(required)]}>{required ? '必須' : '任意'}</span>
      {children}
    </label>
  );
};

const label = (marginTop: number) => css`
  display: block;
  font-size: ${fontSize.body[3]}px;
  margin-top: ${marginTop}px;
`;

const base = css`
  color: ${color.gray[0]};
  padding: 2px 4px;
  margin-right: 8px;
`;

const option = (required = false) => css`
  background-color: ${required ? color.blue[60] : color.gray[60]};
`;
