import { css, keyframes } from '@emotion/react';
import React, { VFC, ReactNode } from 'react';
import Modal from 'react-modal';
import { useScrollBodyLock } from './dependencies/hooks';
import { color } from '@/styles/theme';

Modal.setAppElement('#root');

type ModalChildren = {
  children: ReactNode;
};
type ModalProps = Modal.Props;
type Props = ModalChildren & ModalProps;

export const BaseModal: VFC<Props> = (props) => {
  const { lock, unlock } = useScrollBodyLock();

  return (
    <Modal
      css={modalStyle}
      style={customStyles}
      onAfterOpen={lock}
      onAfterClose={unlock}
      {...props}
    />
  );
};

const customStyles: Modal.Styles = {
  overlay: {
    background: color.overlay,
    position: 'fixed',
    zIndex: 1,
  },
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const modalStyle = css`
  animation: ${fadeIn} 0.2s ease-out;
  background: ${color.gray[0]};
  border-radius: 8px;
  height: fit-content;
  inset: 16px;
  margin: auto;
  max-height: 500px;
  max-width: 480px;
  position: absolute;
`;
