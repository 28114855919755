import React, { VFC } from 'react';
import Lottie, { Options, LottieProps } from 'react-lottie';

type Props = {
  animationData: Options['animationData'];
  height?: LottieProps['height'];
  width?: LottieProps['width'];
  eventListeners?: LottieProps['eventListeners'];
};

export const LottieAnimation: VFC<Props> = ({
  height = 'auto',
  width = 'auto',
  animationData,
  eventListeners,
}) => {
  return (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid',
        },
        animationData: animationData,
      }}
      height={height}
      width={width}
      isClickToPauseDisabled={true}
      ariaRole="presentation"
      eventListeners={eventListeners}
    />
  );
};
