// https://github.com/vercel/next.js/blob/canary/examples/with-react-relay-network-modern/lib/createEnvironment/server.js
import {
  RelayNetworkLayer,
  urlMiddleware,
} from 'react-relay-network-modern/node8';
import RelaySSR from 'react-relay-network-modern-ssr/node8/server';
import { Network, Environment, RecordSource, Store } from 'relay-runtime';

export const initEnvironment = () => {
  const source = new RecordSource();
  const store = new Store(source);
  const relaySSR = new RelaySSR();

  return {
    relaySSR,
    environment: new Environment({
      store,
      network: new RelayNetworkLayer([
        urlMiddleware({
          url: process.env.NEXT_PUBLIC_RELAY_ENDPOINT ?? '',
          headers: {
            'x-hasura-admin-secret': process.env.HASURA_ADMIN_SECRET ?? '',
          },
        }),
        relaySSR.getMiddleware(),
      ]),
    }),
  };
};

export const createEnvironment = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relayData: null | undefined | [[unknown, any]]
) => {
  const source = new RecordSource();
  const store = new Store(source);

  return new Environment({
    store,
    network: Network.create(() => relayData?.[0][1] || Promise.resolve()),
  });
};
