import { FieldValues } from 'react-hook-form';
import { Input } from '@/libs/components/common';
import { HookFormProps } from '@/types';

type Props<T> = HookFormProps<T>;

export const PasswordForm = <T extends FieldValues>({
  name,
  rules,
}: Props<T>) => {
  return (
    <Input
      name={name}
      type="password"
      rules={{
        required: { value: true, message: 'パスワードを入力して下さい' },
        ...rules,
      }}
    />
  );
};
