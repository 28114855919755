import { css } from '@emotion/react';
import React, { VFC } from 'react';
import ReactLoading from 'react-loading';
import { color } from '@/styles/theme';

export const LoadingBubble: VFC = () => (
  <div css={loading}>
    <ReactLoading type="bubbles" color={color.blue[60]} />
  </div>
);

const loading = css`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;
