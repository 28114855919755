import { css } from '@emotion/react';
import React, { VFC } from 'react';
import { fontSize, elevation } from '@/styles/theme';

type Props = JSX.IntrinsicElements['button'];

export const BaseButton: VFC<Props> = (props) => {
  return <button css={baseStyle} {...props} />;
};

const baseStyle = css`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: ${elevation[8]};
  display: flex;
  font-size: ${fontSize.body[1]}px;
  font-weight: 600;
  justify-content: center;
  padding: 8px;

  &:disabled {
    box-shadow: none;
    cursor: auto;
  }

  &:focus:not(:disabled) {
    opacity: 0.8;
  }
`;
