import { css } from '@emotion/react';
import React, { VFC } from 'react';
import { fontSize, color, elevation } from '@/styles/theme';

const stepList = [
  { step: 1, label: '現住所' },
  { step: 2, label: '引越し先' },
  { step: 3, label: '日時' },
  { step: 4, label: '家財' },
];

type Props = {
  currentStep: number;
};

export const StepProgressBar: VFC<Props> = ({ currentStep }) => {
  return (
    <div css={header}>
      <h1 css={headline}>住所入力</h1>
      <div css={progressBar}>
        {stepList.map(({ step, label }) => (
          <div key={step} css={barItem(currentStep >= step)}>
            <span css={barItemCircle(currentStep >= step)}></span>
            <span css={barItemLabel}>{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const header = css`
  align-items: center;
  background-color: ${color.gray[0]};
  box-shadow: ${elevation[16]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
`;

const headline = css`
  font-size: ${fontSize.subhead[1]}px;
  font-weight: bold;
`;

const progressBar = css`
  display: flex;
  flex: 1;
  margin: 16px auto 0;
  width: 100%;
`;

const barItem = (isActive: boolean) => css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  &:not(:first-of-type)::before {
    background-color: ${isActive ? color.blue[60] : color.gray[30]};
    border-radius: 1px;
    content: '';
    height: 2px;
    left: calc(-50% + 12px); /* 中央 - 円の半分 4px + 円と線の余白 8px */
    position: absolute;
    top: 3px;
    width: calc(100% - 24px); /* 横幅 - leftの式の2倍 */
  }
`;

const barItemCircle = (isActive: boolean) => css`
  background-color: ${isActive ? color.blue[60] : color.gray[30]};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const barItemLabel = css`
  font-size: ${fontSize.body[3]}px;
  margin-top: 2px;
`;
