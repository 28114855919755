import React, { ComponentProps } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { BaseSelectChipItem } from '@/libs/components/common';
import { HookFormProps } from '@/types';

type Props<T> = ComponentProps<typeof BaseSelectChipItem> & HookFormProps<T>;

export const SelectChipItem = <T extends FieldValues>(props: Props<T>) => {
  const { name, rules, ...attr } = props;
  const { register } = useFormContext<T>();
  return <BaseSelectChipItem {...register(name, rules)} {...attr} />;
};
