import dayjs, { ConfigType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/ja';
dayjs.extend(isSameOrAfter);

type IsAfter = (subjectDate: ConfigType, date: ConfigType) => boolean;

// ${date} より ${subjectDate} は後か？を算出する関数
export const isAfter: IsAfter = (subjectDate, date) =>
  dayjs(subjectDate).isAfter(dayjs(date));
