import { css } from '@emotion/react';
import React, { forwardRef } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { color, fontSize } from '@/styles/theme';

type CustomProps = {
  isCorrect: boolean;
};

type Props = TextareaAutosizeProps & CustomProps;

export const BaseTextarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ isCorrect, ...attr }, ref) => {
    return <TextareaAutosize css={baseStyle(isCorrect)} ref={ref} {...attr} />;
  }
);

const baseStyle = (isCorrect: boolean) => css`
  background-color: ${color.gray[0]};
  border: 1px solid ${isCorrect ? color.blue[60] : color.divider.onSurface};
  border-radius: 8px;
  font-size: ${fontSize.body[1]}px;
  padding: 8px;
  resize: none;
  width: 100%;
`;
