import { css } from '@emotion/react';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import rabbitThumsUp from '@/animationData/introductionThumsUp.json';
import rabbitComplete from '@/animationData/stepComplete.json';
import { LottieAnimation } from '@/libs/components/common/Lottie/';
import { margin } from '@/styles/space';
import { fontSize, color } from '@/styles/theme';

type Props = {
  title: string;
  subTitle: string;
  animationType: string;
  children?: ReactNode;
  attention?: boolean;
  resetLink?: boolean;
};

export const MailSendComplete: FC<Props> = ({
  title,
  subTitle,
  children,
  animationType,
  attention,
  resetLink,
}) => {
  return (
    <>
      <div css={container}>
        <h2 css={heading}>{title}</h2>
        <p css={subHead(true)}>{subTitle}</p>

        {animationType === 'good' && (
          <LottieAnimation animationData={rabbitThumsUp} width="180px" />
        )}
        {animationType === 'jump' && (
          <LottieAnimation animationData={rabbitComplete} width="180px" />
        )}
        <div css={margin.top[24]}>
          <div>{children}</div>
        </div>
      </div>
      {attention && (
        <div css={information}>
          <h3 css={subHead(false)}>メールが届かない場合</h3>
          <p css={informationItem}>
            ※迷惑メールフォルダ、またはフィルター・転送設定により受信ボックス以外に振り分けられていないかご確認ください。
          </p>
          <p css={informationItem}>
            ※メールの配信に時間がかかる場合がございます。数分お待ち頂いた上で、再度ご確認ください。
          </p>
          <p css={informationItem}>
            ※それでも届かない場合は、下記リンクから再度メールアドレスをご入力いただき、確認用メールを再送信してください。
          </p>
          <Link
            css={textLink}
            to={resetLink ? '/password-reset' : '/mail-address-resend'}
          >
            メールを再送信する
          </Link>
        </div>
      )}
    </>
  );
};

const container = css`
  margin-top: 24px;
  padding: 0 16px;
`;

const heading = css`
  font-size: ${fontSize.headline[2]}px;
  font-weight: bold;
`;

const subHead = (style: boolean) => css`
  font-size: ${fontSize.subhead[1]}px;
  font-weight: bold;
  ${style && `margin-top: 16px;`}
`;

const information = css`
  background-color: ${color.gray[20]};
  margin: 24px auto 0;
  padding: 24px 16px;
`;

const informationItem = css`
  font-size: ${fontSize.body[2]}px;
  margin-top: 16px;
`;

const textLink = css`
  color: ${color.blue[60]};
  display: block;
  font-size: ${fontSize.body[2]}px;
  margin-top: 16px;
  text-align: center;
  text-decoration: underline;
`;
