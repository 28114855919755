import { createEnvironment } from '@samurai/shared/relay';
import { FC, useState, useEffect } from 'react';
import { RelayEnvironmentProvider } from 'relay-hooks/lib/RelayEnvironmentProvider';
import { useAuthUserContext } from './auth/AuthUserProvider';

const useToken = (): { token: string | undefined } => {
  const user = useAuthUserContext();
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    // 初回アクセス時、匿名ユーザーの場合、tokenが不要なので空文字にしてリターン
    if (!user || user.isAnonymous) {
      setToken('');
      return;
    }

    let isMounted = true;
    const asyncGetToken = async () => {
      const idToken = await user.getIdTokenResult();
      if (isMounted) {
        setToken(idToken?.token);
      }
    };
    asyncGetToken();

    return () => {
      isMounted = false;
    };
  }, [user]);

  return { token };
};

export const RelayProvider: FC = ({ children }) => {
  const { token } = useToken();
  return (
    <RelayEnvironmentProvider
      environment={createEnvironment(
        process.env.NX_NEXT_PUBLIC_RELAY_ENDPOINT,
        null,
        token,
        'user'
      )}
    >
      {children}
    </RelayEnvironmentProvider>
  );
};
