import firebase from './config';
import { Message } from '@/libs/message';

class DB {
  protected _db: firebase.firestore.Firestore;

  constructor() {
    this._db = firebase.firestore();
  }
}

class UserDB extends DB {
  public mainQuery(uid: string) {
    return this._db.collection('users').doc(uid);
  }

  public subQuery(uid: string, name: string) {
    return this.mainQuery(uid).collection(name).doc('v1');
  }

  public async setToMain(uid: string, data: firebase.firestore.DocumentData) {
    return await this.mainQuery(uid).set(data, { merge: true });
  }

  public async setToSub(
    uid: string,
    name: string,
    data: firebase.firestore.DocumentData
  ) {
    return await this.subQuery(uid, name).set(data, { merge: true });
  }
}

export const userDB = new UserDB();

class MessageDB extends DB {
  public messagesQuery(roomId: string) {
    return this._db.collection('rooms').doc(roomId).collection('messages');
  }

  public orderBySentAtQuery(roomId: string) {
    return this.messagesQuery(roomId).orderBy('sentAt', 'asc');
  }

  public async submit(message: Message) {
    return await this.messagesQuery(message.roomId).add(message.pureObject());
  }

  public async setReadAt(roomId: string) {
    const batch = this._db.batch();
    const docs = await this.messagesQuery(roomId)
      .where('readAt', '==', '')
      .where('sender', '==', 'company')
      .get();
    docs.forEach((doc) => {
      batch.update(doc.ref, { readAt: new Date().toISOString() });
    });
    return batch.commit();
  }
}

export const messageDB = new MessageDB();
