import { css } from '@emotion/react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { VFC, useState, ReactNode } from 'react';
import { GuideModal } from '@/libs/components/common';
import { color, fontSize } from '@/styles/theme';

type Props = {
  title?: string | undefined;
  modalBody: ReactNode;
};

export const Guide: VFC<Props> = ({ title, modalBody }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <button type="button" css={guide(!!title)} onClick={open}>
        <span css={guideIcon(!!title)}>
          <HelpOutlineIcon />
        </span>
        <span css={guideLabel}>{title}</span>
      </button>
      <GuideModal isOpen={isOpen} modalClose={close} onRequestClose={close}>
        {modalBody}
      </GuideModal>
    </>
  );
};

const guide = (flag: boolean) => css`
  color: ${color.text.mediumEmphasis};
  display: flex;
  font-size: ${fontSize.body[3]}px;
  ${flag && `margin-top: 4px;`}
  padding: 8px 0;
  outline: none;
`;

const guideIcon = (flag: boolean) => css`
  ${flag && `margin-top: 2px;`};
  svg {
    display: block;
    font-size: ${flag ? fontSize.body[1] : fontSize.headline[2]}px;
  }
`;

const guideLabel = css`
  text-decoration: underline;
`;
