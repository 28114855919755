import { Dayjs } from 'dayjs';
import { dayjs } from './localizedDayjs';

export const periodToBeginAndEnd = (
  year: number,
  month: number, // 1-12
  period: string
): {
  begin: Dayjs | undefined;
  end: Dayjs | undefined;
} => {
  const yearMonth = dayjs()
    .year(year)
    .month(month - 1);
  switch (period) {
    case 'early':
      return {
        begin: yearMonth.date(1),
        end: yearMonth.date(15),
      };
    case 'mid':
      return {
        begin: yearMonth.date(10),
        end: yearMonth.date(25),
      };
    case 'late':
      return {
        begin: yearMonth.date(15),
        end: yearMonth.endOf('month'),
      };
    default:
      return { begin: undefined, end: undefined };
  }
};
