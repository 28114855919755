import { css } from '@emotion/react';
import CloseIcon from '@material-ui/icons/Close';
import React, { VFC, ComponentProps } from 'react';
import ModalRabbit from '@/images/modal/modalRabbit.svg';
import { BaseModal } from '@/libs/components/common';
import { fontSize, color, lineHeight } from '@/styles/theme';

type CustomProps = {
  modalClose: () => void;
};

type Props = ComponentProps<typeof BaseModal> & CustomProps;

export const GuideModal: VFC<Props> = ({ modalClose, children, ...attr }) => {
  return (
    <BaseModal css={modalStyle} {...attr}>
      <div css={modalHeader}>
        <img src={ModalRabbit} alt="" css={thumbnailIcon} />
        <button css={closeButton} onClick={modalClose}>
          <CloseIcon />
        </button>
      </div>
      <div css={modalBlock}>{children}</div>
    </BaseModal>
  );
};

const modalStyle = css`
  overflow-y: auto;
  outline: none;
`;

const modalBlock = css`
  font-size: ${fontSize.body[2]}px;
  line-height: ${lineHeight.s};
  margin-top: 16px;
  padding: 0 16px 16px;
`;

const modalHeader = css`
  background: ${color.gray[0]};
  display: flex;
  justify-content: center;
  padding: 16px 0;
  position: sticky;
  top: 0;
`;

const thumbnailIcon = css`
  height: 56px;
  width: 56px;
`;

const closeButton = css`
  position: absolute;
  right: 16px;
  top: 16px;
  outline: none;
`;
